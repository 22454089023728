.floatRight {
    float: right;
}

.actionButton {
    margin: 0 0.1rem;
}

.primaryText {
    color: #f0742b;
}

.successText {
    color: #388E3C;
}

.dangerText {
    color: #ee2e24;
}

.drillDownClickable {
    color: #f0742b;
    font-weight: 600;
}

.drillDownClickable:hover {
    cursor: pointer;
}

.profileDropDownButton:focus {
    box-shadow: none !important;
}

.imageLinkText {
    font-size: 0.7rem;
}

.manifestNotColoaded {
    color: #ee2e24 !important;
    font-weight: 600;
}

.xlmbLastSection {
    margin-bottom: 200px !important;
}